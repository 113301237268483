
.navbar-main {
    box-shadow: 0px 2px 4px 0px #00000040;
    height: 85px;
    left: 0px;
    position: fixed;  
    top: 0px;         
    width: 100%;     
    background: #fff;
    padding: 0px 20px;
    z-index: 1000;   
}
.navbar-inner {
    position: relative;
    top: 24px;         
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}
.navbar-logo {
    display: flex;
    align-items: center;
    gap: 10px;
    flex-direction: row;
}

.fav-info{
    padding-left: 2% !important;
}