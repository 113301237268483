.car-info-card {
  display: flex;
  align-items: center;
  background-color: #08243a;
  color: #ffffff;
  padding: 10px 0px;
  border-radius: 10px;
  margin-bottom: 20px;
  .policyheader-icon{
    background-color: #ffffff1a;
    font-size: 24px;
    padding: 0px 16px 0px 16px;
    border-radius: 0px 16px 16px 0px;
  }

  .info {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    width: 100%;
    // align-items: left;
    .policy_header_row {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 5px;
      // align-items: center;
      // gap: 5px;
      padding-left: 10px;
      border-left: 1px solid #ffffff1a;
      &:last-child {
        border-right: none;
      }
      .label {
        color: rgba(255, 255, 255, 0.78) !important;
      }
      .label,
      .value {
        font-size: 12px;
        font-weight: 400;
        line-height: 18px;
        text-align: left;
      }

      .value {
        display: flex;
        gap: 5px;
        align-items: center;

        &.insurers,
        &.type {
          display: flex;
          gap: 5px;

          .insurer,
          .type {
            background: #f2f2f20d;
            // opacity: ;
            padding: 5px 10px;
            border-radius: 15px;
          }
        }
      }
    }
  }

  .edit {
    button {
      background: #ffffff1a;
      color: #ffffff;
      border: none;
      cursor: pointer;
      font-size: 14px;
      padding: 8px 11px 8px 11px;
      gap: 4px;
      border-radius: 8px 0px 0px 8px;

      &:hover {
        background-color: #006999;
      }
    }
  }
}

@media only screen and (min-width: 280px) and (max-width: 985px) {
  .info {
    display: block;
    margin-left: 10px;
  }

  /* Hide all policy_header_row except RTO */
  .info .policy_header_row {
    display: none;
    width: 10px;
  }

  .info .policy_header_row:first-child {
    /* Assuming RTO is the first child */
    display: flex;
  }
  // /*  */
  .car-info-card .info .policy_header_row {
    flex-direction: column;
  }
  .grid-mobile {
    display: none !important;
  }

  .car-info-card {
    // margin: auto !important;
    padding-top: 10px !important;
    margin-bottom: 10px;
  }
  
  .policyheader-icon {
    height: 50px;
    width: 100px;
    justify-content: center;
    align-items: center;
    padding-left: 10px;
  }
  .edit {
    width: 160px;
    height: 80px;
  }

  .car-info-card .edit button {
    font-size: 16px;
    margin-top: 8px;
  }
  .car-info-card .info {
    display: flex !important;
    gap: 10px;
    justify-content: space-between !important;
  }
}
