.kyc_details_container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

}

.cheque_container {
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: start;
    margin-top: 8px;
}

.add_cheque_details {
    font-family: Poppins;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    text-align: right;
    color: #181818;
    text-decoration: underline;
    cursor: pointer;
}

.cheque-details {
    .flatpickr-input {
        font-size: 14px !important;
        font-weight: 400;
        color: #828282 !important;
    }
}

.view_cheque {
    border-radius: 8px;
    height: 46px;
    width: max-content;
    border: none;
    color: #0691fc;
    text-align: center;
    padding: 12px 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 12px;
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: capitalize;

    &:hover {
        background: #e8f3fc;
    }
}