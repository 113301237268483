.mobile-quote-request-card-container{
  display: flex;
  flex-direction: row;
}
.quote-request-main-container{
  // display: flex;
  .filters-container-mweb {
    display: flex;
    overflow-x: scroll; 
    white-space: nowrap; 
    gap: 10px;
    -ms-overflow-style: none;  
    scrollbar-width: none;  
  }
  
  .filters-container-mweb::-webkit-scrollbar {
    display: none; 
  }
  
  .quote-request-card-container{
    padding:  12px;
  }
}
.quote_request_table_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  // height: calc(100vh - 88px);
  max-height: calc(100vh - 300px); 
  overflow: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
  }
  .quote_table_card {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding: 0px 24px;
    align-self: stretch;
    border-radius: 16px;
    border: 1px solid #e7e7e7;
    background: #fff;
    min-height: 103px;
    box-shadow: 0px 1px 6px 0px #00000026;

    .veichel_content {
      width: 200px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      .vehicle_image {
        padding: 16px;
        border-radius: 8px;
        background: #fafafa;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 16px;

        svg {
          color: #808080;
          width: 24px;
          height: 24px;
        }
      }

      .veichle_number {
        color: #000;
        font-size: 14px;
        font-family: Poppins;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }

      .owner_name {
        color: #808080;
        font-size: 12px;
        font-family: Poppins;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }

    .ticket_content {
      width: 100px;
      display: flex;
      align-items: center;
      justify-content: flex-start;

      .ticket_heading {
        color: #808080;
        font-size: 12px;
        font-family: Poppins;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
      .ticket_id {
        color: #000;
        font-size: 14px;
        font-family: Poppins;
        font-style: normal;
        line-height: normal;
      }
    }

    .status_content {
      width: 220px;
      // display: flex;
      // align-items: center;
      // justify-content: flex-start;
      .status_badge {
        display: flex;
        padding: 4px 16px;
        align-items: flex-start;

        gap: 10px;
        width: max-content;

        border-radius: 8px;

        color: #fff;
        font-size: 12px;
        font-family: Poppins;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
    }

    .date_content {
      height: 37px;
      width: 200px;
      display: flex;
      align-items: center;
      justify-content: flex-end;

      .date {
        color: #666;
        text-align: right;
        font-size: 12px;
        font-family: Poppins;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }

    .more_info {
      display: none;
    }

    // Hover styles
    &:hover {
      background: #e8f3fc;

      .veichel_content {
        .vehicle_image {
          background: #fff;
        }
      }

      .date_content {
        display: none;
      }

      .more_info {
        width: 200px;
        display: flex;
        align-items: center;
        justify-content: flex-end;

        color: #0691fc;
        font-size: 12px;
        font-family: Poppins;
        font-style: normal;
        font-weight: 500;
        line-height: normal;

        .btn_block {
          display: flex;
          width: 85px;
          flex-direction: column;
          align-items: center;
          gap: 3px;
          flex-shrink: 0;

          .btn_title {
            width: max-content;
          }

          &:hover {
            background: #fff;
            padding: 8px 24px;
            border-radius: 8px;
          }
        }
      }
    }
  }

  // .m-24 {
  //   margin: 0px 24px;
  // }
}

.filters_container {
  display: flex;
  align-items: center;
  justify-content: space-between !important;
  width: Fill (1,066px);

  .filter_sort_status {
    display: flex;
    align-items: center;
    justify-content: space-between !important;
    gap: 24px;
  }
}

.filter_by_dropdown {
  min-width: 300px;
}
.sort_by_dropdown {
  min-width: 200px;
}

.date_container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 24px;

  .date_picker_container {
    display: flex;
    align-items: center;
    gap: 0px;
  }
}

.quote_not_found_btn {
  // border-radius: 8px;
  cursor: unset;
  background: transparent;
  height: 46px;
  width: max-content;
  border: none;
  color: #666;
  text-align: center;
  padding: 12px 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 12px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
}
