.multiple_options_btn {
    // option button with hamburger icon and text (options) which is visible on hover
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    cursor: pointer;
    color: #808080;
    
    // hamburger icon already a svg so no need to add svg tag
    svg {
       width: 20px;
         height: 20px;
    }

    // text (options)
    .options_text {
        display: none;
        animation: all 1s ease-out;
    }

    // on hover
    &:hover {
        border-radius: 8px;
        background: #f7f7f7;
        color: #666;
        padding: 12px 8px;

        // hamburger icon already a svg so no need to add svg ta

        // text (options)
        .options_text {
            display: block;
            animation: all 1s ease-out;
        }
    }

    // On click
// Change to: "Other options";
// Animate: Smart animate;
// animation-timing-function: ease-out;
// animation-duration: 300ms;

}