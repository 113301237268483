.mobile-mapping-request-card-container{
  display: flex;
  flex-direction: row;
}

.quote_requeste_doc_upload_container {
  .doc_card {
    display: flex;
    padding: 8px 16px;
    gap: 16px;
    // justify-content: space-between;
    align-items: center;
    width: 100%;
    border-radius: 8px;
    background: #fafafa;
    margin: 16px 0px;

    .doc_name_content {
      display: flex;
      // width: 400px;
      flex: 1 1 33%;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;

      .doc_name {
        color: #666;
        font-size: 14px;
      }
    }

    .doc_status_content {
      display: flex;
      // width: 550px;
      flex: 1 1 33%;
      align-items: center;
      justify-content: flex-start;
      // break-word: break-all;
      .doc_status {
        display: flex;
        padding: 4px 16px;
        align-items: center;
        gap: 10px;
        border-radius: 27px;
        background: #ff3e78;
        width: max-content;
        color: #fff;
        font-size: 12px;
      }
    }

    .doc_btn_content {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      flex: 1 1 33%;
      // .upload_btn_container {
      //   .upload_btn {
      //     display: flex;
      //     flex-direction: row;
      //     align-items: center;
      //     padding: 8px 16px;
      //     gap: 8px;
      //     height: 34px;
      //     background: #e6f4ff;
      //     border-radius: 8px;
      //     border: none;

      //     font-family: "Poppins";
      //     font-style: normal;
      //     font-weight: 600;
      //     font-size: 12px;
      //     line-height: 18px;
      //     text-align: right;
      //     text-transform: capitalize;
      //     color: #0691fc;
      //   }
      // }
    }
  }

  .doc_submit {
    padding: 12px 48px;
    border-radius: 8px;
    display: flex;
    justify-content: center;
  }
}

.quote_request_shared_quote_container {
  .quote_request_shared_quote_list {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

 


  .quote_card_for_modal {
    background: #e6e6e6;
    // .logo_image {
    // }
    .modal_actions {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      gap: 16px;
    }
    .modal_action_button,
    .quote_status_btn .modal_action_button a{
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
    .no_actions {
      width: unset;
    }
  }

  .quote_unavailable_card {
    background: #e6e6e6;
    .logo_image {
      // grey scale the image
      filter: grayscale(100%);
    }
  }

  .quote_form_container {
    .input {
      background: #fff;
    }
  }
}
