@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap");

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html,
body {
  max-width: 100vw;
  font-family: "Poppins", sans-serif;
  font-display: swap;
  font-style: normal;
}

a {
  text-decoration: none;
}

/* Make Cards Match Height */
.match_height > [class*="col"] {
  display: flex;
  flex-flow: column;
}

.match_height > [class*="col"] > .card {
  flex: 1 1 auto;
}

.mb-40 {
  margin-bottom: 40px;
}

/* Hide waves on mobile */
@media only screen and (max-width: 600px) {
  .waves {
    display: none;
  }
}

/* Dark mode support */
@media (prefers-color-scheme: dark) {
  html {
    color-scheme: dark;
  }
}

/* Style for view labels */
.view_label {
  color: #0691fc;
}

/* Hide number input arrows */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
