.dashboard-wrapper{
  .date_container{
    display: flex;
    justify-content: space-between;
    padding: 16px;
    margin-right: 20px ;
    .user-name{
      // font-family: Poppins;
      // font-size: 18px;
      // font-weight: 500;
      // line-height: 24px;
      // letter-spacing: 0em;
      // text-align: left;
      font-family: Poppins;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      text-align: left;
    }
    .flatpickr-input{
      font-size: 14px !important;
      font-weight: 400 !important;
    }
  }
}
.main-div{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  // margin-top: 16px;
  .w_full{
    width: 100%;
  }
}
.main-container{
  width: calc(33% - 16px);
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.card-container {
  background-color: white;
  border-radius: 4px;
  overflow: hidden;
  width: 100%;
  // 
  display: flex;
  flex-direction: column;
  // border-bottom: 3px solid #0691FC;
  .green{
    color: #23963D;
  }
  
  box-shadow: 0px 2px 9.600000381469727px 0px #00000026;
  // gap: 16px;
  border-radius: 16px;
  .card-upperhalf{
    display: flex;
    flex-direction: column;
    // gap: 16px;
    padding: 16px;
    padding-bottom: 0px;
  }
}

.card-top {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  align-items: center;
  padding: 12px 16px;
  gap: 8px;
}

.card-mid {
  display: flex;
  flex-direction: row;
  // justify-content: space-between;
  font-weight: 400;
  gap: 8px;
  align-items: center;
  font-size: 14px;
  color: #666666;
  padding: 6px 22px 12px 22px;
  gap: 10px;
  
}
.card-mid div{
  font-weight: 600;
  font-size: 20px;
  color: black;
}
.card-mid span{
  font-weight: 400;
  font-size: 12px;
}
.card-bottom {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  background: linear-gradient(7.64deg, #D4ECFF 6.59%, rgba(255, 255, 255, 0) 94.82%);
  .active{
    background: #fff;
    border-bottom: 3px solid #0691FC;
    svg{
      color: #0691FC;
    }
  }
  .mapping-green {
    display: flex;
    flex-direction: row;
    justify-content: center;
    span{
      color: #23963D;
    }
  }
}

.card-bottom div {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 14px;
  color: #333;
  width: 33%;
  padding: 8px 0;
  cursor: pointer;
}

.recent-bookings {
  display: flex;
  flex-direction: column;
  gap: 12px;
    h2 {
      font-size: 14px;
      font-weight: 400;
      line-height: 18px;
      letter-spacing: 0em;
      text-align: left;
      color: #666666;
      //capatalize the text
      text-transform: uppercase;
  }
}

.booking-items {
  display: flex;
  width: 100%;
  // flex-wrap: wrap;
  flex-direction: column;
  gap: 8px;
}

.booking-item {
  background-color: white;
  border-radius: 12px;
  width: 100%;
  padding: 8px;
  // margin-right: 8px;
  // margin-bottom: 8px;
  // width: calc(33% - 16px);
  display: flex;
  justify-content: space-between;
  align-items: center;
  // border: 1px solid red;
   .booking-details {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 12px;
    .registration-data{
      display: flex;
      flex-direction: column;
      font-size: 12px;
    }
    .img{
      background: #F7F7F7;
      padding: 8px;
      border-radius: 8px;
      // gap: 10px;
    }
  }
}
.registration-data{
  .status{
    display: flex;
    gap: 4px;
    align-items: center;
    font-size: 10px;
    font-weight: 400;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: left;
    color: #23963D;  
  }
}
.issued-date{
  color: #B3B3B3;
  font-family: Poppins;
  font-size: 10px;
  font-weight: 400;
  line-height: 15px;
  text-align: left;


}
.notfound-card{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  span{
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
    color: #141414;
  }
}
.view-all{
  display: flex;
  align-self: center;
  align-items: center;
  width: fit-content;
  color: #0691FC;
  cursor: pointer;
  justify-content: center;
  padding: 12px 0px 12px 0px;
  gap: 8px;

}
.lock-screen{

  .lock-screen-content{
    position: fixed;
    top: 50%;
    left: 50%;
    z-index: 15;
    transform: translate(-13%, -5%);
    display: flex;
    flex-direction: column;
    background: #FFFFFF;
    padding: 32px; 
    gap: 8px;
    border-radius: 16px;
    border: 1px solid #B3B3B3 ;
    align-items: center;
    h2{
      // font-family: Roboto;
        font-size: 16px;
        font-weight: 700;
        line-height: 19px;
        letter-spacing: 0em;
        text-align: left;
    }
    p{
      color: #666666;
      font-size: 12px;
      font-weight: 400;
      line-height: 14px;
      letter-spacing: 0em;
      text-align: left;
    }
  }
}
/* media query */
@media only screen and (min-width: 350px) and (max-width: 985px) {
  /* set-lock-screen */
  .lock-screen-content {
    /* Styles specific to .lock-screen-content */
    padding: 12px !important;

    h2{
      font-size: 12px !important;
    }
    p{
      font-size: 10px !important;
      text-align: center !important;
    }
  }
  .dashboard-top{
    margin-bottom: 10px !important;
  }
  // -----------Card's Section fix--//

  .main-div{
     flex-direction: column !important;
  }
  .main-container{
    width: 100% !important;
    padding: 10px 13px!important;
  }
  .card-mid div {
    font-size: 18px !important;
    line-height: 25px ;
  }
  .card-top{
    font-size: 14px;
    font-weight: 500 ;
  }
  .card-container .card-upperhalf{
    padding: 0px !important;
  }
  .card-bottom div{
    font-size: 13px !important;
  }
  .lock-screen .lock-screen-content{
    transform: translate(-50%, -50%);
  }
  // .card-bottom .active svg{
  //   // width: 40px;
  //   // height: 40px;
  //   // border-radius: 43px;
  //   // padding: 10px 8px 10px 8px ;
  //   // gap: 10px;
  //   // background-color:  rgba(247, 247, 247, 1);
  //   // ;
  // }

  .dashboard-wrapper{
    max-height: calc(100dvh - 296px) !important; 
    overflow: auto !important;
    scrollbar-width: none !important;
    -ms-overflow-style: none  !important;
  
    &::-webkit-scrollbar {
      display: none !important; 
    }
  }
  .profile-completed-mweb{
    max-height: calc(100dvh - 160px) !important ; 
    overflow: auto !important ;
    scrollbar-width: none !important ;
    -ms-overflow-style: none !important ;

    &::-webkit-scrollbar {
      display: none !important ;
    }
  }
  .dashboard-wrapper .date_container .user-name {
    font-size: 12px ;
    font-weight: 500;
    line-height: 15px;
  }
  .dashboard-wrapper .date_container{
    margin-right: 0px;

    .flatpickr-input{
      font-size: 16px !important;
    }
  }
}
// ------------
