.form_input_container {
  .wrapper {
    position: relative;
  }

  .icon {
    padding: 4px 10px 4px 10px;
    position: absolute;
    box-sizing: border-box;
    top: 50%;
    left: 2px;
    transform: translateY(-50%);
    display: flex;
    align-items: center;
  }

  .input {
    height: 45px;
    width: 100%;
    box-sizing: border-box;
    padding-left: 3rem;
    border-radius: 8px;
    border: 1px solid #cccccc;
    background: #ffffff;
    color: #828282 !important;
    font-family: Poppins;
    // font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .no_icon {
    padding-left: 0.6rem !important;
  }

  textarea:focus,
  input:focus {
    outline: none;
  }

  textarea:disabled,
  input:disabled {
    outline: none;
    background-color: #cccccc;
    border: none;
  }

  .errro_icon {
    top: 35% !important;
  }

  // .wrapper::after {
  //   content: "*";
  //   position: absolute;
  //   top: 10px;
  //   left: 300px;
  //   color: #f00;
  // }
}
.form_input_container .input:hover{
  border: 1px solid #0691fc !important;
}
@media screen and (max-width: 600px) {
  .input {
    font-size: 16px !important;
    font-weight: 500 !important;
  }
  .input::placeholder{
    font-size: 16px !important;
  }
}