.mapping-table-mweb-container {
    overflow: hidden;
    height: 100%; 
    width: 100%;
    .mapping-table-mweb {
        display: flex;
        flex-direction: column;
        gap: 12px;
        padding: 8px;
        max-height: calc(100vh - 224px);
        overflow-y: auto;

        &::-webkit-scrollbar {
            display: none;
        }

        scrollbar-width: none;

        -ms-overflow-style: none;
    }
}
