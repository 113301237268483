.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  list-style: none;
  padding: 0;
  margin: 0;
  padding-top: 20px;
}

.pagination_list {
  display: flex;
  list-style-type: none;
  padding: 0;
  margin: 0;
  justify-content: center;
  align-items: center;
  gap: 5px;
  .page-item {
    .pagination_item_nav,
    .pagination_item {
        display: flex;
        justify-content: center;
        align-items: center;
      padding: 5px 12px;
      text-decoration: none;
      color: #333;
      width: 30px;
      height: 30px;
      border: none;
      border-radius: 100%;
      background-color: transparent;
      font-size: 14px;
      font-weight: 400;
      cursor: pointer;
      transition:
        background-color 0.3s,
        color 0.3s;

      &:hover {
        background-color: #f0f0f0;
      }
      span {
        font-size: 32px;
      }
    }

    .pagination_item_nav {
        color: #333;
        border: none;
        background-color: transparent;
        border-radius: 100%;
        padding: 4px 16px;
        span {
            font-size: 24px;
          }
      &:disabled {
        color: #ccc;
        cursor: not-allowed;
        border: none;
        background-color: transparent;
        border-radius: 100%;
        padding: 8px 16px;
        span {
            font-size: 32px;
          }
      }
    }

    .pagination_item_active {
      background-color: #007bff;
      color: white;
      border: 1px solid #007bff;

      &:hover {
        background-color: #0056b3;
      }
    }

    &.active {
        .pagination_item {
            background-color: #007bff;
            color: white;
            border: 1px solid #007bff;
            &:hover {
            background-color: #0056b3;
            }
        }
    }

  }
}