.veichle_details {
  .veichle_number {
    color: #000;
    font-size: 18px;
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: capitalize;
    margin: 0;
  }

  .veichle_owner_name {
    margin: 0;
    color: #808080;
    font-size: 12px;
    font-family: Poppins;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}

.veichle_extra_details {
  .detail {
    color: #666;
    font-size: 12px;
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
}

.view_quote_details_container {
  display: flex;
  // align-items: center;
  justify-content: space-between;

  .request_details_container {
    width: 220px;
    min-width: 220px;
    // height: 548px;
    min-height: 548px;
    // max-width: max-content;
    border-right: 1px solid #e6e6e6;
    padding-right: 24px;
  }

  .shared_quote_section {
    // width: 535px;
    width: 100%;
  }

  .change_only_once_text {
    color: #D79006;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    display: flex;
    align-items: center;
    gap: 2px;
    
  }

  .activity_container {
    width: 300px;
    min-width: 300px;
    // height: 548px;
    min-height: 548px;
    max-width: max-content;
    border-left: 1px solid #e6e6e6;
    padding: 24px;
  }
}

.quote_activity_container {
  // height: 480px;
  min-height: 480px;
  // max-width: max-content;
  .activity_content {
    height: 480px;
    overflow-x: hidden;
    overflow-y: scroll;
    .activity_card {
      display: flex;
      align-items: center;
      gap: 8px;
      align-self: stretch;

      .quote_requested {
        color: #181818;
        font-family: Poppins;
        font-size: 10px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }

      .quote_timestamp {
        color: #666;
        font-family: Poppins;
        font-size: 10px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
  }

  .comment_input_container{
    display: flex;
    align-items: center;
    position: relative;
  }

  .comment_input {
    display: flex;
    padding: 4px 40px 4px 16px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    border-radius: 35px;
    border: 0.5px solid #ccc;
    background: #fff;
    height: 38px;
    width: 100%;

    /* Card shadow 1 */
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);

    &::placeholder {
      color: #B3B3B3;
      font-size: 12px;
    }
  }

  .error_class {
    padding: 0px 16px;
  }

  .send_icon {
    position: absolute;
    right: 4px;
    cursor: pointer;
  }
}

.doc_name {
  color: var(--grey-10, #181818);
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
}

.component_section_name,.section_name {
  color: #000;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.component_section_name::before {
  content: "";
  margin-right: 12px;
  height: 52px;
  border: 2px solid #0691fc;
}

.quote_documents_container {
  .document_list {
    .doc_name {
      color: #181818;
      font-family: Poppins;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-transform: capitalize;
    }
  }
}

.card_background {
  border-radius: 16px;
  background: #fff;
  box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.25);
}

.documnets_list {
  display: flex;
  flex-direction: column;
  gap: 8px;
  .document_item {  
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px;
    border-bottom: 1px solid #e6e6e6;
  }
}

// vertical stepper line with green dot and green line
.vertical_stepper_lines {
  position: relative;
  width: 1px;
  height: 100%;
  background: #ccc;
  margin-left: 8px;
  margin-right: 8px;
  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 1px;
    height: 100%;
    background: #0691fc;
  }
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 1px;
    height: 100%;
    background: #0691fc;
  }
}
