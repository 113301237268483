.submittedModal {
  height: 65vh !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.requestNewQuoteModalContainer {
  position: fixed;
  right: 20px;
  bottom: 0;
  max-height: 90vh;
  height: 100%;
  width: 50vw;
  max-width: 600px;
  z-index: 9;
  background: white;
  border-radius: 24px 24px 0px 0px;
  border-bottom: 1px solid #e6e6e6;
  box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.2);

  .modal_header {
    background: #00223c;
    display: flex;
    padding: 16px;
    align-items: flex-start;
    gap: 12px;
    border-radius: 24px 24px 0px 0px;
    color: #fff;
    // border-bottom: 1px solid #e6e6e6;

    .header_items {
      display: flex;
      width: 90px;
      padding: 8px 0px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 8px;
      color: #fff;

      .veichle_name {
        color: #ccc;
        font-family: Poppins;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }

      .active_veichle_name {
        color: #fff;
        font-family: Poppins;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
    }

    .active_header_item {
      border-radius: 12px;
      background: #004880;
    }
  }

  .min_height_form {
    // height: 75vh;
    height: 100%;
    // overflow-y: scroll;
    overflow-x: hidden;
  }

  // .min_height_form::-webkit-scrollbar {
  //   display: none;
  // }

  .newQuoteRequestFormContainer {
    padding: 0px 16px;
    height: 100%;

    .doc_field_container {
      border-radius: 8px;
      max-height: 42px;
      background: #f2f2f2;
      padding: 4px 16px;
      margin-bottom: 32px;

      .doc_field {
        display: flex;
        justify-content: space-between;
        align-items: center;
        align-self: stretch;

        .doc_name {
          color: var(--grey-40, #666);
          font-family: Poppins;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          text-transform: capitalize;
        }

        .only_pdf,
        .doc_type {
          color: #727272;
          font-family: Poppins;
          font-size: 10px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }

        .upload_doc_field_btn {
          display: flex;
          padding: 8px 16px;
          align-items: center;
          gap: 4px;
          border-radius: 8px;
          border: none;
          color: #0691fc;
          background: transparent;
          text-align: right;
          font-family: Poppins;
          font-size: 12px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          text-transform: capitalize;
        }
      }
    }


    .flatpickr-input {
      font-size: 14px !important;
      font-weight: 400 !important;
    }


    .form_data {
      // height: calc(100% - 160px);
      max-height: 700px;
      overflow-y: scroll;
      width: 100%;
      overflow-x: hidden;
    }

    .form_body {
      width: calc(100% - 8px);
    }
  }
}

.request-details {
  border: 1px solid #ccc;
  padding: 10px;
  margin: 10px;
  border-radius: 5px;
}

/* Styling for the header */
.request-header {
  background-color: #f0f0f0;
  font-size: 18px;
  font-weight: bold;
  padding: 8px;
  border-bottom: 1px solid #ccc;
  border-radius: 5px 5px 0 0;
}

.divider {
  display: block;
  text-align: center;
  overflow: hidden;
  white-space: nowrap;

  // divider text display prop

  .divider-text {
    position: relative;
    display: inline-block;
    font-size: 0.9375rem;
    padding: 0 1rem;

    color: var(--grey-40, #666);
    text-align: center;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;

    // divider icon font size

    i,
    svg {
      height: 1rem;
      width: 1rem;
      font-size: 1rem;
    }

    // divider border

    &:before,
    &:after {
      content: "";
      position: absolute;
      top: 50%;
      width: 9999px;
      border-top: 1px solid#e6e6e6;
    }

    // divider spacing
    &:before {
      right: 100%;
    }

    &:after {
      left: 100%;
    }
  }

  // divider positions config

  &.divider-start {
    .divider-text {
      float: left;
      padding-left: 0;

      &:before {
        display: none;
      }
    }
  }

  &.divider-start-center {
    .divider-text {
      left: -25%;
    }
  }

  &.divider-end {
    .divider-text {
      float: right;
      padding-right: 0;

      &:after {
        display: none;
      }
    }
  }

  &.divider-end-center {
    .divider-text {
      right: -25%;
    }
  }

  // divider styles

  &.divider-dotted {
    .divider-text {

      &:before,
      &:after {
        border-style: dotted;
        border-width: 1px;
        border-top-width: 0;
        border-color: #e6e6e6;
      }
    }
  }

  &.divider-dashed {
    .divider-text {

      &:before,
      &:after {
        border-style: dashed;
        border-width: 1px;
        border-top-width: 0;
        border-color: #e6e6e6;
      }
    }
  }
}

.width_178 {
  width: 178px;
}

.new_quote_form_buttons {
  padding: 10px;
  height: 60px;
}

.remove_addons_btn {
  width: fit-content !important;
  margin-top: 16px !important;
  font-size: 14px;
  font-weight: 500;
}

// scroll bar styling for the form
::-webkit-scrollbar {
  width: 5px;
}

@media screen and (min-width: 769px) {
  .requestNewQuoteModalContainer {
    width: 50vw;
    border-radius: 24px 24px 0px 0px;
    border-bottom: 1px solid #e6e6e6;
    position: fixed;

    .modal_header {
      border-radius: 24px 24px 0px 0px;
    }
  }

  .min_height_form {
    .form_data {
      height: calc(100% - 160px);
    }
  }
}

@media screen and (max-width: 768px) {
  .requestNewQuoteModalContainer {
    width: 100vw;
    border-radius: 0px;
    border-bottom: none;
    height: 100vh;
    max-height: 100vh;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    .modal_header {
      border-radius: 0px;
    }
  }

  .min_height_form {
    .form_data {
      height: calc(100% - 180px);
    }
  }
}

@media screen and (max-width: 480px) {
  .requestNewQuoteModalContainer {
    width: 100vw;
    border-radius: 0px;
    border-bottom: none;
    height: 100vh;
    max-height: 100vh;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin-top: 85px;
    .modal_header {
      border-radius: 0px;
      display: flex;
      overflow-x: auto;   
      white-space: nowrap; 
      gap: 5px;          
      scrollbar-width: none;
    }
  
    .modal_header::-webkit-scrollbar {
      display: none;
    }
  
    .header_items {
      flex: 0 0 auto;
      min-width: 80px;
      text-align: center;
    }
  }

  .min_height_form {
    .form_data {
      max-height: calc(100% - 250px) !important;
    }
  }

  .newQuoteRequestFormContainer {
    padding: 0px 8px;
  }
}
