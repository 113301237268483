.grid-component-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  margin-top: -24px !important;

  .grid_modal_header {
    background: #00223c;
    display: flex;
    padding: 16px;
    align-items: flex-start;
    gap: 12px;
    border-radius: 0px 0px 24px 24px;
    color: #fff;
    width: fit-content;
    // border-bottom: 1px solid #e6e6e6;

    .header_items {
      display: flex;
      width: 90px;
      padding: 8px 0px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 8px;
      color: #fff;

      .veichle_name {
        color: #ccc;
        font-family: Poppins;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }

      .active_veichle_name {
        color: #fff;
        font-family: Poppins;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
    }

    .active_header_item {
      border-radius: 12px;
      background: #0090ff;
    }
  }
  .grid-options {
    display: flex;
    flex-direction: row;
    width: fit-content !important;
    background-color: white;
    justify-content: flex-start;
    flex-wrap: wrap;
    padding: 32px;
    gap: 30px;
    border-radius: 24px;

    @media only screen and (min-width: 768px) {
      .grid-options-container {
        min-width: calc(25% - 30px);
        max-width: calc(25% - 30px);
        // max-width: 25%;
      }
    }
    .flatpickr-input {
      font-size: 14px !important;
    }
    .grid-options-container:last-child {
      margin-left: auto;
      margin-right: 26px;
    }
    .secondary_button {
      width: 100%;
      height: 50px;
      // background-color: #0691FC;
      color: white;
      // padding: 4px;
    }
  }
}

@media only screen and (min-width: 280px) and (max-width: 985px) {
  .grid-component-container {
    gap: 0px;
  }

  /* grid header  */
  .grid_modal_header {
    width: 100% !important;
    gap: 4px !important;
    height: 86px ;
    margin-top: 16px;
    border-radius: 0px 0px 12px 12px !important;
  }
  .veichle_name {
    font-size: 9px;
  }
  .active_veichle_name {
    font-size: 9px;
  }
  /*-------------------------------*/
  /*grid optioins  mob-ui */
  .grid-options {
    display: flex !important;
    gap: 20px !important;
    // width: 366px;
    max-height: calc(100vh - 238px); 
    overflow: auto;
    scrollbar-width: none;
    -ms-overflow-style: none;
    border-radius: 0px !important;
  
    &::-webkit-scrollbar {
      display: none;
    }
  }
  .grid-options-container {
    display: flex;
    flex-direction: column;
    width: 100% !important;
  }
  .form-options {
    width: 100% !important;
  }
  // .css-yr30m6-control {
  //   width: 100% !important;
  // }
  .multi_select {
    width: 100% !important;
    min-width: 305px !important;
  }
  .error_class {
    font-size: 12px !important;
    font-weight: 500;
  }

  // .flat_picker_date {
  //   min-width: 300px;
  // }
  .error_class {
    width: 300px !important;
    margin: 3.5px;
  }
  .error_class small {
    font-size: 10px;
    font-weight: 600;
  }
  .secondary_button {
    min-width: 300px !important;
    font-size: 14px;
    display: flex !important;
    text-align: center;
  }

  .grid-options-content-container {
    width: 92%;
  }
  .grid-options-container:last-child {
    width: 100% !important;
    display: flex;
    justify-content: center;
    margin-left: 0px !important;
    margin-right: 0px !important;
  }

  .no_data-mweb{
    margin: auto;
    margin-top: 4px;
    font-weight: 500;
    font-size: 14px;
  }
}
