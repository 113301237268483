.error-boundary {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: #f8f9fa;
    color: #dc3545;
    font-size: 18px;
    text-align: center;
  
    h1 {
      font-size: 28px;
      margin-bottom: 12px;
    }
  
    p {
      margin-bottom: 16px;
      max-width: 400px;
    }
  
    button {
      padding: 10px 20px;
      background-color: #dc3545;
      color: #fff;
      border: none;
      border-radius: 5px;
      cursor: pointer;
      font-size: 16px;
      transition: background 0.3s ease;
  
      &:hover {
        background-color: #b02a37;
      }
    }
  }
  