.file_selector_container {
  display: flex;

  .file_upload_btn_container {
    .file_upload_doc_field_btn {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 8px 16px;
      gap: 8px;
      height: 34px;
      background: #e6f4ff;
      border-radius: 8px;
      border: none;

      font-family: "Poppins";
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 18px;
      text-align: right;
      text-transform: capitalize;
      color: #0691fc;
    }
  }

  .file_name_container {
    width: 100%;
    max-width: 200px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;
    border: none;
    color: #0b0f12;
  }

  .file_name {
    color: #0D8B38; 
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    text-align: right;
    // ellipsis overflow
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

  }

  .file_icon {
    color: #0D8B38;
  }

  .remove_file_btn {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px 8px;
    justify-content: center;
    gap: 8px;
    height: 34px;
    width: 34px;
    // background: #e6f4ff;
    border-radius: 8px;
    border: none;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    text-align: right;
    text-transform: capitalize;
    // red color
    color: #920F17;
    cursor: pointer;

    &:hover {
      background: #debcbe;
    }
  }

  .remove_file_btn:hover {
    background: #debcbe;
  }

}
