
// General container
.cards-container {
    display: flex !important; /* Adjust for desired layout */
    flex-wrap: wrap !important; /* Adjust for wrapping */
    width: 100%;
    gap: 2rem;
  }
  
  // Individual card container
  .sub-card-container {
    background-color: #fff;
    border-radius: 24px;
    box-shadow: 0px 2px 4px 0px #00000026;
    // gap: 32px;
    display: flex ; 
    flex-direction: column;
    width: 100%;
    /* Content padding */
    // padding: 1rem 1.5rem 1.5rem 1rem;
  
    /* Header styles */
    .sub-card-container .card-content-info p:nth-child(2) { /* Select the booking ID paragraph */
        white-space: nowrap; /* Prevent text wrapping */
        overflow: hidden; /* Hide excess text */
        text-overflow: ellipsis; /* Add ellipsis if text is truncated */
        max-width: 12px; /* Set maximum width */
      }
      
    .card-header {
      display: flex;
      align-items: center;
      margin-bottom: 1rem;
      width:100%;
    //   height: 55px;
      padding:16px 16px 16px 16px;
      justify-content: space-between;
      border-bottom: 1px solid #D9D9D9;
      /* Vehicle info */
      .vehicle-info {
        display: flex;
        align-items: center;
        width: 33%;
        img {
          width: 50px;
          height: 50px;
          border-radius: 50%;
          margin-right: 1rem;
          
        }
  
        .vehicle-num-name {
          h3 {
            color: #333;
            font-size: 16px;
            margin-bottom: 0;
          }
  
          p {
            //styleName: Desktop/Overline;
            font-family: Poppins;
            font-size: 12px;
            font-weight: 400;
            line-height: 18px;
            letter-spacing: 0em;
            text-align: left;
            color: #181818;

          }
        }
      }
  
      /* Insurer name */
      h3 {
        color: #333;
        font-size: 16px;
        margin-bottom: 0;
      }
  
      /* Premium amount */
      p {
        // color: #28a745; /* Assuming green from image */
        font-size: 14px;
        font-weight: bold;
        margin-bottom: 0;
      }
    }
  
    /* Content styles */
    .card-content {
      /* Adjust for number of content sections */
      display: flex;
      justify-content: space-between;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 1rem;
      padding: 0 16px 16px 16px;
      .card-content-info {
        display: flex;
        flex-direction: column;
        justify-content: center;
        // align-items: center;
        h3 {
          color: #777;
          font-size: 14px;
          margin-bottom: 0.5rem;
        }
  
        p {
          color: #333;
          font-size: 14px;
          margin-bottom: 0;
        }
      }
      .booking-id{
        text-align: center;
      }
    }
  
    /* Footer styles */
    .booking-card-footer {
      display: flex;
      align-items: center;
      justify-content: center;
      border-bottom-left-radius: 24px;
      border-bottom-right-radius: 24px;
      background: #E8F3FC;
      height: 44px;
      a {
        color: #0691FC;
        font-size: 14px;
        text-decoration: none;

        padding: 5px 10px;
        // border: 1px solid #28a745; /* Assuming green from image */
        border-radius: 4px;
      }
    }
  }
  .filters_container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: Fill (1,066px);
    // padding: 20px 0px 20px 0px;


    .filter_sort_status {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 24px;
    }
  }
  
  .vehicle_image {
    padding: 16px;
    border-radius: 8px;
    background: #fafafa;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 16px;

    svg {
      color: #005495;
      width: 24px;
      height: 24px;
    }
  }
  .booking-card-wrapper{
    width: calc(50% - 1rem);

  }
  .pos-rel{
    position: relative;
  }
  .pos-abs{
    position: absolute;
  } 
  .w-fit{
    width: fit-content;
  }

  .booked{
    background: #32C053;
    color: #fff;
    padding: 2px 8px;
    border-radius: 6px;
    font-family: Poppins;
    top: calc(50% - 10px);
    left: calc(50% - 36px);
  }
  .cancelled{
    background: #E82727;
    color: #fff;
    padding: 2px 8px;
    border-radius: 6px;
    top: calc(50% - 10px);
    left: calc(50% - 32px);
  }
  .d_flex{
    display: flex;
  }
  .flex_wrap{
    flex-wrap: wrap;
  }
  .justify_center{
    justify-content: center;
  }
  .align_center{
    align-items: center;
  }
  .flex_col{
    flex-direction: column;
  }
  .flex_row{
    flex-direction: row;
  }
  .gap_24{
    gap: 24px;
  }
  .d_flex.flex_wrap {
    /* Adjust the gap between items as needed */
    gap: 24px;
    /* Set the number of columns for the flex items */
    columns: 4;
  }
  
  .d_flex.flex_wrap > div {
    width: calc(25% - 24px) ;
    overflow: hidden;
  }
  .grey_95{
    background: #f2f2f2 !important;
  }
  .container{
    width: 100%;
    // gap: 2rem;
    padding: 24px;
    border-radius: 16px;
  }
  .v_key{
    font-family: Poppins;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    color: #808080;
  }
  .v_value{
    font-family: Poppins;
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    color: #181818;

  }
  .booking_header{
    display: flex;
    width: 100%;
    // height: 80px;
    padding: 16px 16px 16px 16px;
    color: #fff;
    align-items: center;
    background-color: #00223C;;
    margin-bottom: 24px;
    justify-content: space-between;
  }
.policy_details{
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  overflow: hidden;
  
  .container{
    border-radius: 0%;
  }
  .premium{
   background-color: #CEF6D7 ;
   justify-content: center;
   padding:  8px;
   gap: 1rem;
  }
}
.booking_heading{
  border-left: 3px solid var(--Primary-2, #0691fc);
    display: flex;
    padding: 8px 20px;
    // align-items: flex-start;
    gap: 10px;
    align-items: center;
    // justify-content: space-;
}

.align_center{
  align-items: center;
}
.space_between{
  justify-content: space-between;
}
.business_type{
  background-color: #E8F3FC;
  padding: 4px 8px;
  border-radius: 6px;
  gap: 10px;
  font-size: 12px;
  color: var(--blue-dark);
  font-weight: 600;
  text-align: left;
}
.booking-logo-container{
  min-width: 33%;
  display: flex;  
  align-items: center;
  justify-content: center;
}
.coin-container{
  display: flex;
  align-items: center;
  justify-content: flex-end;
  min-width: 33%;
}
// m-web scss
.mweb-booking-card-container{
  display: flex;
  flex-direction: row;

}
.mweb-booking-card-top{
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-height: calc(100dvh - 223px);
  overflow-y: auto;
  scrollbar-width: none;
  margin-top: 3px;
}
.last-card-Mbooking{
  margin-bottom: 10px;
}
.mweb-booking-card-main{
  display: flex;
  background-color: white;
  height: 160px;
  border-radius: 8px;
  box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.15);
  width: 100%;
  position: relative;
}
.mweb-leftside-booking{
  display: flex;
  gap: 22px;
  align-items: center;
}
.mweb-bookingcard-sec-2{
  display: flex;
  justify-content: space-between;
}
.mweb-customer-details{
  display: flex;
  flex-direction: column;
  gap: 3px;
  width: 30%;
}
.mweb-customer-details-text{
  font-size: 400;
  font-size: 10px;
  color: rgba(102, 102, 102, 1);
  word-wrap: break-word;
  text-wrap: wrap;
}
.mweb-customer-details-text-2{
  font-size: 10px;
  font-weight: 500;
  color: rgba(24, 24, 24, 1);
  word-wrap: break-word !important;
  text-wrap: wrap;
}

.m-web-booking-card-footer{
  position: absolute;
  width: 100%;
  bottom: 0px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content:space-between;
  background-color: rgba(232, 243, 252, 1);
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
  padding: 2%  3%;
}
.m-web-booking-filters-container{
 display: flex;
 flex-direction: row;
 gap: 20px;  
 -ms-overflow-style: none;  
 padding: 10px;
 scrollbar-width: none;  
 overflow-x: scroll; 
}