.deals-list {
  width: 100%;
  margin: 0 auto;
  padding: 0px;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 24px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  gap: 10px;

  .deals-list-header {
    background: #e8f3fc;
    display: flex;
    padding: 0px 40px;
    .header-data {
      display: flex;
      padding: 16px 32px;
      width: 100%;
      gap: 8px;
      .item {
        width: 25%;
        display: flex;
        justify-content: flex-start;
        color: #00223c;
        font-size: 13px;
        font-weight: 600;
        line-height: 19.5px;
        text-align: left;
      }
      .item:nth-child(1) {
        padding-left: 15px;
      }
    }
  }
  .deals-list-body {
    padding: 20px 40px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    overflow-y: auto;
  }
}

@media only screen and (min-width: 280px) and (max-width: 985px) {
  .deals-list .deals-list-header .header-data {
    padding: 16px 0px;
    gap: 9px;
    width: 100%;
  }
  .deals-list .deals-list-header {
    padding: 0 2px;
    width: 100%;
  }
  .deals-list .deals-list-header .header-data .item {
    font-size: 10px;
  }
  .header-data {
    display: none !important;
  }
  .deals-list-body {
    height: calc(100vh - 300px);
    overflow: auto;
    scrollbar-width: none;
    -ms-overflow-style: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }
}
