.sidebar-main {
    width: 100%;
    position: fixed;
    display: flex;
    background-color: #FFFFFF;
    height: 76px;
    bottom: 0px;
    z-index: 2;
    border-top:1px solid #E3E4E6 ;
  }
  
  .inner-menu-mweb{
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    position: relative;
    justify-content: space-around;
  }
    
  .menu-name {
    font-size: 10px;
    font-weight: 500;
  }
  
  .inner-menu {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    justify-content:space-around;
    
    position: relative;
  }
  
  .inner-menu-item {
    display: flex;
    flex-direction: column;
    width: 50px ;
    gap: 9px;
    align-items: center;
    padding: 10px;
    cursor: pointer; 
    transition: all 0.3s ease;
    color: #000000;
    text-decoration: none; 
    border: none; 
    outline: none; 
  }
  
  .menu-name {
    font-size: 10px;
    font-weight: 500;
  }
  
  .inner-menu-item.active-sidebar {
    color: #0065FF;
  }

  
.raise-request-sidebar {
  position: absolute;
  top: 10%; 
  left: 50%;  
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
 padding: 10px 50px;
}
// .raise-icon{
//   position: fixed;
//   bottom: 48px;
//   right: 178px;
  
// }
.menu-name-raise{
  font-size: 10px;
  position: absolute;
  top: 78px;
  color: black;
  font-weight: 500;
  left: 44px;
}

.inner-menu-mweb .inner-menu-item:nth-child(3) {
margin-right: 80px;
}

// Mweb-Raise-Request-MOdal
.m-web-raise-header {
  background-color: rgba(232, 243, 252, 1);
  border-radius: 12px;
  width: 110px;
  height: 72px;
  text-align: center;
  position: relative;
}
.m-web-raise-icon {
  position: absolute;
  top: 24px;
  right: 45px;
  color: rgba(6, 145, 252, 1); /* Ensure the color applies properly */
}
.m-web-raise-modal-text{
font-size: 14px;
font-weight: 500;
text-align: center;
word-wrap: break-word;
}



