.decline_all_modal_container {
    display: flex;
    flex-direction: column;
    gap: 16px;
    .decline_all_note {
      display: flex;
      align-items: center;
      justify-content: center;
      align-self: center;
      gap: 8px;
      background-color: #FCEFE8;
      color:#D79006;
      width: max-content;
      padding: 8px 16px;
      border-radius: 8px;
      font-size: 12px;
    }
    .decline_all_modal_btns {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      gap: 16px;
    }
}