.internal_quote_section_container {
  .pending_badge {
    display: flex;
    padding: 4px 16px;
    align-items: flex-start;
    gap: 10px;
    border-radius: 27px;
    background: #ff3e78;
    color: #fff;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .fill_details_pin_code {
    padding: 4px 16px;
    border-radius: 27px;
    background: #e8f3fc;
    width: fit-content;
    text-align: center;
    right: 346px;
    position: absolute;
    z-index: 99;
    margin-top: -55px;
    color: #0691fc;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .agency_container {
    .agency_heading {
      color: #000;
      font-family: Poppins;
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
    .agency_heading::before {
      content: "";
      margin-right: 12px;
      height: 52px;
      border: 2px solid #0691fc;
    }

    .inspection_container {
      display: flex;
      padding: 4px 8px;
      align-items: flex-start;
      gap: 10px;
      align-self: stretch;
      border-radius: 8px 8px 0px 0px;
      background: var(--light-orange, #fcefe8);
      color: #d79006;

      .inspection_heading {
        font-family: Roboto;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
    }

    .agency_content {
      border-radius: 0px 0px 16px 16px;
      background: var(--primary-light, #e8f3fc);

      .agency_key {
        color: #808080;
        font-family: Poppins;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }

      .agency_value {
        color: #181818;
        font-family: Poppins;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
  }

  .color_danger {
    background: #ff9d3b !important;
  }

  .payment_container {
    .payment_heading {
      color: #fff;
      font-family: Poppins;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    .payment_value {
      color: #fff;
      font-family: Poppins;
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }

    .seperator_line {
      color: #56758d;
    }

    .make_payment_earliest {
      border-radius: 8px;
      background: rgba(248, 184, 60, 0.15);
      color: #f8b83c;
      font-family: Roboto;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }

    .have_made_payement {
      display: flex;
      padding: 16px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      align-self: stretch;
      border-radius: 16px;
      background: #013258;

      .have_made_payment_title {
        color: #fff;
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }

      .confirm_payment {
        color: #b3b3b3;
        font-family: Poppins;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }

    .payment_validating_title {
      color: #fff;
      font-family: Poppins;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }

    .payment_validating_details {
      color: #b3b3b3;
      font-family: Poppins;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
}
