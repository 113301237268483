.mapping-card-mweb {
  background: white;
  border-radius: 8px;
  box-shadow: 0px 1px 6px 0px #00000026;

  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  font-family: Arial, sans-serif;
  width: 100%;

  .mapping-header-mweb {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: bold;
    font-size: 16px;

    .vehicle-container-mweb {
      display: flex;
      align-items: center;
      gap: 8px;
    }
    .view-more-container-mweb {
      display: flex;
      align-items: center;
      gap: 8px;
      flex-direction: row;
      .chevron-container {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 24px;
        height: 24px;
        background: #e8f3fc;
        border-radius: 100%;
        cursor: pointer;
      }
    }

    .vehicle-image-mweb {
      width: 40px;
      height: 40px;
      background: #f7f7f7;
      border-radius: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .mapping-number-mweb {
      flex-direction: column;
    }

    .mapping-registration-mweb {
      flex-grow: 1;
      font-weight: 600;
      font-size: 10px;
      color: #000000;
    }

    .mapping-product-mweb {
      display: block;
      flex-grow: 1;
      font-weight: 500;
      font-size: 10px;
      color: #666666;
    }

    .mapping-id-mweb {
      flex-grow: 1;
      font-weight: 500;
      font-size: 10px;
      color: #000000;
    }

    .view-more-mweb {
      display: flex;
      align-items: center;
      font-weight: 600;
      font-size: 10px;
      color: #000000;
      cursor: pointer;

      svg {
        margin-left: 4px;
      }
    }
  }

  .mapping-details-mweb {
    display: flex;
    justify-content: space-between;
    .wrap {
      text-wrap: wrap;
    }
    .mapping-item-mweb {
      display: flex;
      flex-direction: column;

      .mapping-policy-mweb {
        flex-grow: 1;
        font-weight: 600;
        font-size: 10px;
        color: #000000;
      }

      .label-mweb {
        font-weight: 400;
        font-size: 10px;
        color: #666666;
      }

      .value-mweb {
        font-weight: 600;
        font-size: 10px;
        color: #000000;
      }
    }
  }
}
.mapping-filters-container-mweb {
  display: flex;
  overflow-x: scroll;
  white-space: nowrap;
  gap: 10px;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
