.icon_input_container {
  .wrapper {
    position: relative;
  }

  .icon {
    padding: 4px 10px 4px 10px;
    position: absolute;
    box-sizing: border-box;
    top: 30px;
    left: 2px;
    transform: translateY(-50%);
  }

  .input {
    height: 59px;
    width: 100%;
    box-sizing: border-box;
    padding-left: 3rem;
    border-radius: 8px;
    border: 1px solid #cccccc;
    background-color: white;
    color: #666666;
    font-family: Poppins;
    text-align: left;
  }

  .no_icon {
    padding-left: 0.6rem !important;
  }

  textarea:focus,
  input:focus {
    outline: none;
  }

  textarea:disabled,
  input:disabled {
    outline: none;
    background-color: #cccccc;
    border: none;
  }

  .errro_icon {
    top: 35% !important;
  }

  // .wrapper::after {
  //   content: "*";
  //   position: absolute;
  //   top: 10px;
  //   left: 300px;
  //   color: #f00;
  // }
}
@media only screen and (max-width: 600px) {
 .input{
  font-size: 16px;
  font-weight: 500;
  line-height: 21px;
 }
}
