.quote_dynamic_header {
  height: 246px;
  width: 100%;
  margin: 0 auto;
  border-radius: 16px;
  // background: #fff;
  box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.25);

  display: flex;
  padding: 40px 0px;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .header_title {
    color: #000;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: capitalize;
    margin-top: 32px;
  }

  .header_introduction {
    color: #666;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 8px;
    text-align: center;
  }
}

.quote_dynamic_header_no_background {
  min-height: 246px;
  width: 100%;
  margin: 0 auto;
  border-radius: 16px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .header_title {
    color: #000;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: capitalize;
    margin-top: 32px;
  }

  .header_introduction {
    color: #666;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 8px;
    text-align: center;
  }
}
