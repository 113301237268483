.notes-accordion-main {
  position: fixed;
  bottom: 0;
  right: 1px;
  width: 340px; 
}
.set-notes-header{
  border-radius: 8px 0px 8px 0px  !important;

}

.accordion {
  --bs-accordion-color: #212529 !important;
  color: #6B5000 !important;
  --bs-accordion-bg: #FFEDB7 !important;
  --bs-accordion-border-radius: 8px 8px 0 0 !important;
  --bs-accordion-inner-border-radius: 8px 8px 0 0 !important;
  --bs-accordion-active-color: #6B5000 !important;
  // border-radius: 8px 8px 0 0 !important;
  --bs-accordion-active-bg: none !important;
  --bs-accordion-btn-focus-border-color: none !important;
  --bs-accordion-btn-padding: 10px 1px !important;
  --bs-accordion-btn-focus-box-shadow: none !important;
  --bs-accordion-btn-icon-transform: rotate(180deg) !important;
  --bs-accordion-btn-active-icon: url('../../assets/images/notes/Vector (1).svg') !important;
  --bs-accordion-btn-icon: url('../../assets/images/notes/Vector (1).svg') !important;
  --bs-accordion-body-padding-x: 10px !important;
  transition: transform 0.3s ease !important;
}

.accordion-button {
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  border-radius: 8px 0px 8px 0px  !important;
  color: #6B5000;
}

.accordion-flush .accordion-item .accordion-button {
  border-radius: 8px 8px 0 0 !important;
 
}
.accordion-flush .accordion-item .accordion-button .open{
  border-top: 0px solid #B08500  !important;
}

.acco-body {
  background-color: #FFEDB7;
  padding: 6px 1px;
  border-radius: 10px;
  max-height: 0;
  overflow: hidden; 
  transition: max-height 0.4s ease-out, padding 0.4s ease-out;
  // Set initial padding for closed state
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 0px;
  padding-right: 0px;
}

.acco-body.open {
  max-height: 328px; /* Adjust this value based on your content height */
  // Set padding for open state
  // padding-top: 6px;
  // padding-bottom: 6px;


} 
.acco-body-contain {
  display: flex;
  flex-direction: column;
  background-color: #FFFFFF66;
  gap: 24px;
  margin: 5px !important;
  padding: 16px 8px 16px  25px !important;
  border-radius: 8px ;
 
}

.accordion-item {
  border-radius: 8px;
 
}

.notes-header {
  padding: 0;
  display: flex;
  align-items: center;
  gap: 10px;
  border-radius: 8px 8px 0 0;
  transform: translateY(0) !important;
  border-top: 1px solid #B08500;
  border-left: 1px solid #B08500;
  border-right: 1px solid #B08500;
  // transition: transform 0.4s ease-out !important;
   
}

.notes-header.open {
  transform: translateY(-100%) !important;
  transition: transform 0.4s ease !important;
  border-radius: 8px 8px 0 0 !important;


}
.notes-logo {
  position: absolute;
  left: 6px;
}
.accordion-button:not(.collapsed) {
  box-shadow: none  !important;

}

@media only screen and (min-width: 280px) and (max-width: 985px) {
  .notes-accordion-main {
    width: 100%;
    z-index: 9999;
    display: block !important;
    position: fixed; /* or absolute */
    bottom: 75px !important; /* Adjust as needed */
    left: 0;
    // background: white; /* Ensure visibility */
  }
}
