.noc_description {
  // font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  color: #666666;
}

.m-32 {
  margin: 16px 0px;
}

.noc_info_content {
  background: #f2f2f2;
  border-bottom: 1px solid #f7f7f7;
  border-radius: 8px;
  padding: 8px;
  margin-top: 20px;

  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: normal;
}

.noc_type {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #000000;
}

.noc_type_error {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #ff2222;
  height: 12px;
}
.options-inner-items{
  margin-top: 15px;
}
.option-heading{
  font-size: 16px;
  font-weight: 500;
}
.options-text{
  font-size: 14px;
  color: #666666;
  font-weight: 500;
}
@media only screen and (min-width: 280px) and (max-width: 600px) {
  .noc-cards{
    border: 1px solid #CCCCCC;
    border-radius: 8px;
   padding-right: 15px;
  }
  .noc-cards-inner-items{
    padding: 24px 15px 24px 15px;
  }
  .modal_header{
    font-size: 20px;
  }
  .noc_description{
    margin-top: 6px;
  }
  .noc_type{
    font-size: 12px;
    line-height: 18px;
    font-weight: 600;
  }
  .noc_info_content{
    font-size: 14px; 
    font-weight: 500;
    line-height: 17px;
  }
  .options-text{
    font-size: 12px;
  }
}