.mobile-tab-container {
  display: flex;
  flex-direction: column;
  color: #000;
  white-space: nowrap;
  font-family: poppins;
  max-height: calc(100vh - 160px);
  overflow-y: auto; 
  max-width: 25%;
  scrollbar-width: none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
  }
}
  
  .mobile-tab-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 4px;
    padding: 16px 28px;
    min-width: 50px;
    cursor: pointer;
    font-size: 12px;
    color: #555;
    background-color: #E8F3FC;
    .mobile-tab-count {
      font-size: 12px;
      border-radius: 100%;
      width: 24px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      &.active{
        background: #0691FC;
        color: #fff;
      }
    }
    .icon {
      font-size: 20px;
      margin-bottom: 5px;
      width: 24px;
      height: 24px;
    }
    .active-icon {
      color: #0691FC;
    }
  
    &.active {
      background: #fff;
      font-weight: 600;
      font-size: 10px;
      border-right: 3px solid #0691FC;
    }
    &.active + * {
      border-top-right-radius: 12px ;
    }
    
    /* Next sibling */
    *:has(+ &.active) {
      border-bottom-right-radius: 12px ;

    }
    .label{
      text-wrap: wrap;
      text-align: center;
    }
  }
