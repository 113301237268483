.custom_sidebar_container {
  .ps-sidebar-container {
    background-color: #ffffff !important;
    border: none !important;
  }

  .css-um1o6k {
    display: none !important;
  }

  .logo_full {
    cursor: pointer;
    height: 64px;
    width: 186px;
    margin: 16px auto 16px 25px;
  }
  .logo_small {
    cursor: pointer;
    margin-left: 20px;
    height: 43px;
  }

  color: var(--gray-dark);
  font-size: 14px;

}

.sidebar_menu {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  padding: 0 16px;

  ul {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
  
}


.new_quote_request_menu {
  display: flex;
  padding: 8px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  border-radius: 8px;
  background: #fff;
  box-shadow: 0px 4px 9px 0px rgba(0, 0, 0, 0.15);
  height: 128px;
  // width: 246px;
  z-index: 15 !important;
  
  position: fixed;
  top: 145px;
  left: 20px;

  .menu_bottom {
    border-bottom: 1px solid #f2f2f2;
  }

  .menu_item {
    display: flex;
    width: 100%;
    padding: 16px 8px;
    align-items: center;
    gap: 8px;

    .menu_heading {
      color: #000;
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      text-transform: capitalize;
      font-family: Poppins;
    }
  }

  .menu_item:hover {
    border-radius: 8px;
    background: #e8f3fc;
  }
}


.sidenav_btn_container {
  position: relative;
}

.sidenav_btn {
  gap: 8px;
  background: #0691FC;
  border-radius: 8px;
  border: none;
  color: #fff;
  font-size: 14px;
  margin: auto;
  width: calc(100% - 40px);
  padding: 13px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 5;
  box-shadow: 0px 4px 9px 0px rgba(0, 0, 0, 0.15);
  &:hover {
    background: var(--linear-gradient-blue-left);
    transition: background 1s linear;
  }

  &:focus {
    outline: none;
    background: var(--linear-gradient-blue-right);
    transition: background 1s linear;
  }

  &:active {
    background: var(--linear-gradient-blue-right);
    transition: background 1s linear;
  }

  &:disabled {
    background: #666666;
    &:hover {
      background: #666666;
    }
    svg {
      fill: #666666;
      width: 20px;
      height: 20px;
    }
  }
}

.disabled_menu {
  cursor: not-allowed !important;
  &:hover {
    background: #b3b3b3;
  }
}