
.mweb-details-heading{
    font-size: 500;
    font-size: 14px;
    color: #000;
}
.mweb-accordion-header{
    display: flex;
    gap: 20px;
    align-items: center;
    width: 100%;
}
.mweb-acco-details {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;  
}
.inner-details-section {
    width: calc(50% - 5px);  // Ensures two items per row (adjust for spacing)
    display: flex;
    flex-direction: column;
    gap: 5px;
}
.mweb-booking-header {
    position: fixed;
    width: 100%;
    top: 0;
    height: 70px !important;
    background-color: white;
    z-index: 10;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
    display: flex !important;
    justify-content: center !important;
    gap: 30px !important; 
    border-radius: 0px !important;
      }
  .mweb-booking-body {
    flex-grow: 1;
    overflow-y: scroll;
    height: 100vh; 
    padding: 10px;
  }
  .mweb-booking-body::-webkit-scrollbar {
    display: none; 
  }
  .mweb-booking-accordion-sections{
    display: flex;
    flex-direction: column;
    gap: 30px;
    margin-top: 19%;
  }
  .m-web-booking-modal-card-footer{
      position: absolute;
       width: 100%;
       height: 35px;
       background-color: rgba(232, 243, 252, 1);
       border-bottom-left-radius: 7px;
       bottom: -2px;
       left: 0px;
       border-bottom-right-radius: 7px;
       display: flex;
       flex-direction: row-reverse;
       gap: 10px;
       align-items: center;
       justify-content: center;

  }
.premimum-section-items{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.mweb-accordion-header-icon{
  margin-left: auto;
  width: 25px;
  height: 25px;
  text-align: center;
  border-radius: 50%;
  color: rgba(6, 145, 252, 1);
  background-color:  rgba(232, 243, 252, 1);
}
.m-web-premimum-footer{
padding: 0px 3%;
background-color: rgba(232, 243, 252, 1);
width: 100%;
position: absolute;
bottom: -7px;
left: 0px;
border-bottom-right-radius: 7px;
border-bottom-left-radius: 7px;
height: 34px;
display: flex;
justify-content: space-between;
align-items: center;
}
.m-webbooking-header-top{
    display: flex;
    flex-direction: column;
    gap: 6px;
    text-align: center;
  }
  .m-web-booking-modal   .modal-content {
    border-radius: 0px ;
  }
// accordion scss 
.accordion-main {
    .accordion-item {
      background-color: rgba(255, 255, 255, 1) ;
    //   border: 6px solid #ddd;
         border: none ;
        box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.16);
         

      .accordion-header {
        background: none !important;
        border: none;
        // padding: 10px;
  
        &:hover {
          background: none !important; // No hover effect
        }}  
        .accordion-button::after{
          display: none !important;
        }
  
      // Fix the issue where the background changes when opened
      .accordion-button {
        background: none !important; 
        box-shadow: none !important;
        color: inherit !important;
        transition: none !important;
  
        &:focus {
          box-shadow: none !important;
        }
  
        &:not(.collapsed) {
          background: none !important; 
          box-shadow: none !important;
        }
      }
  
      .accordion-body {
        position: relative;
        background-color: rgba(255, 255, 255, 1) !important;
        color: #000;
        transition: all 0.3s ease;
      }
    }
  }

 
  