.modal_container {
  .modal-content {
    border: none;
    border-radius: 24px;
    padding: 10px 40px;
    min-height: 316px;

    .modal_header {
      border: none;
      font-family: "Poppins";
      font-weight: 600;
      font-size: 24px;
      line-height: 36px;
      text-transform: capitalize;
      color: #000000;
      text-align: center;

      .btn-close {
        position: absolute;
        right: 25px;
        top: 20px;
        background-color: #e6e6e6;
        border-radius: 50px;
        font-size: 14px !important;
      }
    }
  }
  &.no_modal_padding {
    .modal-content {
      padding: 0;
      overflow: hidden;
    }
    padding: 0;
  }

  .extra_toggle {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 34px;
    width: 34px;
    border-radius: 50px;
    background: #e6e6e6;
    border: none;
    color: #323232;
  }
}

.modal_backdrop_fade {
  background: rgba(0, 0, 0, 0.3) !important;
  backdrop-filter: blur(15px) !important;
}

.header_bottom {
  color: #666666;
  height: 10px;
  width: 100%;
}
@media only screen and (min-width: 280px) and (max-width: 600px) {
  .modal_container .modal-content {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    height: auto;
    max-height: 65vh;
    min-height: 0px;
    overflow-y: auto;
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    padding: 15px 16px;
    display: flex;
    flex-direction: column;

    .modal_header {
      padding-left: 20px !important;
      font-family: Poppins;
      font-size: 20px !important;
      font-weight: 600;
      line-height: 30px;
      text-align: left;
      flex-shrink: 0;
    }
    .btn-close {
      position: absolute !important;
      right: 15px !important;
      display: none !important;
    }
    .modal-body {
      flex-grow: 1;
      overflow-y: auto;
      padding: 12px 10px 10px 10px;
    }
  }
  /* Full-screen modal for pages that need 100vh */
  .full-height-modal .modal-content {
    height: 100vh !important;
    max-height: none !important; /* Override max-height */
  }

  .rounded .modal-content {
    border-radius: 0px !important;
  }
}
