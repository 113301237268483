.mobile-sidebar {
    position: fixed;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100vh;
    background: #fff;
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.2);
    transition: left 0.3s ease-in-out;
    // z-index: 1000;
    // overflow-y: auto; /* Enables scrolling */
    // z-index: 1000; /* Ensures it's above other elements */
  }
  
  .mobile-sidebar.open {
    left: 0; 
  }
  
  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 999;
    display: none;
  }
  
  .mobile-sidebar.open + .overlay {
    display: block;
  }
  .menu-main{
    position: relative;
    height: 100vh;
    // width: calc(100% - 20px); 
    width: 100%;
    background-color: white;
    // margin: 0px 10px;
  }
  .profile-section{
    background: linear-gradient(to bottom, rgba(212, 236, 255, 1), rgba(255, 255, 255, 0)); 
    padding-top: 42px;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  .profile-inner{
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-evenly;
  }
  .mweb-profile-image{
    width: 52px;
    height: 52px;
    border-radius: 100px;
    border: 3px solid rgba(6, 145, 252, 1);
  }
  .profile-name{
    color: rgba(0, 0, 0, 1);
    letter-spacing: 0%;
    font-size: 14px;
    font-weight: 600;
   
  }
  .profile-email{
    font-size: 12px;
    font-weight: 400;
  }
  .rm-partner-info{
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: rgba(212, 236, 255, 1);
    height: 50px;
    border-radius: 8px;
     margin: 0px 5%;
  }
.menu-sidebar-items{
  display: flex;
  flex-direction: column;
  gap: 30px;
  margin-top: 24px;
  margin-left: 5%;
  margin-right: 5%;
}
.inner-sidebar-items{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.sidebar-item-text{
  display: flex;
  align-items: center;
  gap: 20px;
}