.policy-deals-container {
  display: flex;
  flex-direction: column;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); // Apply box shadow to the container
  height: 87vh; // Set the maximum height for the container
  overflow: hidden; // Hide overflow to create a scrollable area
  .policy-deals-date {
    // margin-top: 20px;
    display: flex;
    background: #ffff;
    padding: 6px 10px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    width: fit-content;
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
    text-align: center;
    margin: 0 auto;
  }
}
.policy-deals-header {
  flex: 0 0 auto;
}
.policy-deals-date {
  flex: 0 0 auto;
}

.deals-listing-container {
  flex: 1 1 auto; // Allow this section to grow and shrink as needed
  overflow-y: auto; // Enable vertical scrolling
}
.deals-list-body {
  margin: 0px;
}
.deals-list-item {
  margin: 5px 0;
}

@media only screen and (min-width: 280px) and (max-width: 985px) {
  .policy-deals-container {
    height: 75vh;
    box-shadow: none;
  }
}